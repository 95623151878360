import { find, every } from 'lodash';
import React, { Component } from 'react';

import LabelledSelect from './LabelledSelect';
import LabelledInput from './LabelledInput';
import { sizeSort } from '../utils';

class CartSkuSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: {},
      quantities: props.skus.reduce((o, s) => ({ ...o, [s.product_sku_id]: (props.quantities || {})[s.product_sku_id] || 0 }), {})
    };
  }

  getOptionsFromSkus(skus) {
    const options = skus.reduce(
      (o, sku) => o.concat(sku.options),
      []
    ).reduce(
      (o, option) => ({
        ...o,
        [option.option_axis]: Array.from(new Set((o[option.option_axis] || []).concat(option.option_name)))
      }),
      {}
    );
    return options;
  }

  getSkuFromOptions(skus, value) {
    return skus.filter(sku => every(value, (v, k) => {
      return find(sku.options, (option) => {
        return option.option_axis === k && option.option_name === v;
      });
    })).map(sku => sku.product_sku_id);
  }

  onSetQuantity(axis, option, quantity) {
    const { skus } = this.props;
    const { value } = this.state;

    const sku = this.getSkuFromOptions(skus, { ...value, [axis]: option });
    if (1 == sku.length) {
      this.setState(state => ({
        quantities: {
          ...state.quantities,
          [sku[0]]: parseInt(quantity, 10)
        },
        allow_save: Object.values(state.quantities).reduce((t, q) => t + q, 0) + quantity > 0
      }), () => this.props.onChange(this.state.quantities));
    }
  }

  onSelectOption(axis, option) {
    const { skus } = this.props;
    const options = this.getOptionsFromSkus(skus);
    this.setState(state => ({
      value: {
        ...state.value,
        [axis]: option
      }
    }));
  }

  render() {
    const { skus, inPopup = false } = this.props;
    const { value, quantities } = this.state;
    const options = this.getOptionsFromSkus(skus);
    const axes = Object.keys(options).sort();
    const filterOutAxes = ['dimension'];
    const filteredAxes = axes.filter(axis => !filterOutAxes.includes(axis));
    const firstAxis = axes.shift();
    const firstAxisOptions = (options[firstAxis] || []).map(o => ({ key: o, value: o }));
    const filteredOptions = value[firstAxis] ?
      this.getOptionsFromSkus(
        skus.filter(
          sku => !!sku.options.filter(o => o.option_axis === firstAxis && o.option_name === value[firstAxis]).length
        )
      ) :
      [];
    return (
      <div className="row popup-content column" style={{ paddingBottom: '100px' }}>
        <div style={{ backgroundColor: '#f2f2f2' }}>
          <div style={{ clear: 'both' }}></div>
          {axes.length > 0 &&
            <div className="row">
              <div className="small-12 columns">
                <LabelledSelect
                  label={firstAxis}
                  labelStyle={{ textTransform: 'capitalize' }}
                  options={firstAxisOptions}
                  value={value[firstAxis]}
                  onChange={this.onSelectOption.bind(this, firstAxis)}
                  inPopup={inPopup}
                />
              </div>
            </div>}
          {filteredAxes.length > 0 && value[firstAxis] ?
            <div className="row column">
              {filteredAxes.map(axis => {
                let axisOptions = Object.assign([], filteredOptions[axis]);
                if ('size' === axis) {
                  axisOptions.sort((a, b) => sizeSort(a, b));
                }
                return (
                  <div key={`axis-${axis}`} className="row">
                    {axisOptions.map(option =>
                      <div key={`axis-${axis}-option-${option}`} style={{ marginRight: '8px' }}>
                        <LabelledInput
                          labelOrientation="side"
                          label={option}
                          type="number"
                          value={quantities[this.getSkuFromOptions(skus, { ...value, [axis]: option })[0]]}
                          min={0}
                          onChange={this.onSetQuantity.bind(this, axis, option)}
                        />
                      </div>
                    )}
                  </div>
                );
              }
              )}
            </div> : (0 === axes.length &&
              <div className="row column">
                <div className="row">
                  {options[firstAxis].map(option =>
                    <div key={`option-${option}`} style={{ marginRight: '8px' }}>
                      <LabelledInput
                        labelOrientation="side"
                        label={option}
                        type="number"
                        value={quantities[this.getSkuFromOptions(skus, { ...value, [firstAxis]: option })[0]]}
                        min={0}
                        onChange={this.onSetQuantity.bind(this, firstAxis, option)}
                      />
                    </div>
                  )}
                </div>
              </div>)}
        </div>
      </div>
    );
  }
}

export default CartSkuSelect;
