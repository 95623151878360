import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import ColorBreakdown from './ColorBreakdown';
import LabelledSelect from './LabelledSelect';
import LabelledInput from './LabelledInput';
import CartSkuSelect from './CartSkuSelect';

import { closePopup, createCartSummaryPopup } from '../actions/popup';
import { createAddTemp, createDeleteTemp } from '../actions/temp';
import { getFullOptionItem } from '../selectors';

import { toTitleCase } from '../utils';

class AddToQuotePopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      color_breakdowns: props.quote ? props.quote.color_breakdowns :
        [{
          color: {
            color_id: '',
            color_name: ''
          },
          sizes: props.filtered_size_list,
          quantity_color: 0,
          new: true
        }],
      total_quantity: props.quote ? props.quote.total_quantity : 0,
      allow_save: false,
      allow_add_color: false,
      quantities: props.quote ? props.quote.skus : null,
      loading: true
    };

    _.bindAll(this, ['handleChangeValue', 'addColor', 'handleRemove', 'handleSave', 'onFocus']);
  }

  componentDidMount() {
    let no_color_length = this.props.quote ? this.props.quote.color_breakdowns.map(c => c.color.color_name == '') : null;

    if(no_color_length && _.indexOf(no_color_length, true) == -1) {
      this.setState({ allow_add_color: true });
    }
    if(this.props.full_item.updated) {
      this.setState({ loading: false });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.full_item.updated) {
      this.setState({ loading: false });
    }
    if(nextProps.quote) {
      this.setState({ color_breakdowns: nextProps.quote.color_breakdowns, total_quantity: nextProps.quote.total_quantity });
    }
  }

  handleChangeValue(e, params, size_id, size_name, idx) {
    let new_color_breakdowns = this.state.color_breakdowns.slice();
    let state;
    let int_e = e ? parseInt(e) : 0;

    if(params) {
      if(params == 'color') {
        new_color_breakdowns[idx].color.color_id = e;
        new_color_breakdowns[idx].color.color_name = this.props.filtered_color_list[e].color_name;
      }else if(params == 'quantity_color') {
        if(new_color_breakdowns[idx].color.color_name == '') {
          new_color_breakdowns[idx].color.color_name = 'none';
        }
        new_color_breakdowns[idx].quantity_color = int_e;
      }else if(params == 'size' && size_id && size_name) {
        let new_size = {
          size_id: size_id,
          size_name: size_name,
          quantity: int_e
        };
        new_color_breakdowns[idx].sizes = Object.assign({}, this.state.color_breakdowns[idx].sizes, {[size_id]: new_size });

        if(new_color_breakdowns[idx].color.color_name == '' && this.props.item_color_dropdown.length == 0) {
          new_color_breakdowns[idx].color.color_name = 'none';
        }

        let pre_quantity_color = Object.assign({}, new_color_breakdowns[idx].sizes);
        if(pre_quantity_color) {
          let quantity_color = Object.values(pre_quantity_color).map(q => q.quantity).reduce((a, b) => a + b, 0);
          new_color_breakdowns[idx].quantity_color = quantity_color;
        }
      }
    }else{
      new_color_breakdowns[0].color.color_name = 'none';
      new_color_breakdowns[0].quantity_color = int_e;
    }

    this.setState({ color_breakdowns: new_color_breakdowns }, function() {
      if(idx) {
        if(this.state.color_breakdowns[idx].color.color_name != '' && this.state.color_breakdowns[idx].quantity_color > 0) {
          this.setState({ allow_save: true, allow_add_color: true });
        }
      }else{
        if(this.state.color_breakdowns[0].color.color_name != '' && this.state.color_breakdowns[0].quantity_color > 0) {
          this.setState({ allow_save: true, allow_add_color: true });
        }
      }
    });
  }

  addColor() {
    _.forEach(this.state.color_breakdowns, function(value, key) {
      value.new = false;
    });

    let new_color_breakdown = {
      color: {
        color_id: '',
        color_name: ''
      },
      sizes: this.props.filtered_size_list,
      quantity_color: 0,
      new: true
    };

    this.setState({ color_breakdowns: this.state.color_breakdowns.concat(new_color_breakdown), allow_add_color: false, allow_save: false });
  }

  handleRemove(idx) {
    let new_color_breakdowns = this.state.color_breakdowns.slice();
    new_color_breakdowns.splice(idx, 1);

    this.setState({ color_breakdowns: new_color_breakdowns });
  }

  handleSave() {
    let save_state = {};
    save_state.item_id = this.props.currentItemId;
    save_state.item_name = this.props.full_item.item_name;
    save_state.color_breakdowns = this.state.color_breakdowns;
    save_state.total_quantity = this.state.color_breakdowns.slice().map(b => b.quantity_color).reduce((a, b) => a + b, 0);
    _.forEach(save_state.color_breakdowns, function(value, key) {
      save_state.color_breakdowns[key].new = false;

      if(save_state.color_breakdowns[key].color.color_name == '') {
        save_state.color_breakdowns.splice(key, 1);
      }
    });
    if (this.state.quantities) {
      save_state.total_quantity = Object.values(this.state.quantities).reduce((t, q) => t + q, 0);
      save_state.skus = this.state.quantities;
    }

    this.props.onCreateAddTemp(this.props.currentItemId, save_state);
    this.props.onClosePopup();
    this.props.onCreateCartSummaryPopup();
  }

  onFocus(e) {
    e.target.select();
  }

  renderSelectSizeColor() {
    const { item_color_dropdown, item_size_dropdown, currentItemId } = this.props;
    return (
      <div className="row popup-content column" style={{paddingBottom: '100px'}}>
        {item_color_dropdown.length > 0 || item_size_dropdown.length > 0 ?
          <div>
            {this.state.color_breakdowns.map((cb, idx) =>
              <ColorBreakdown
                key={idx}
                idx={idx}
                color_breakdowns={this.state.color_breakdowns}
                currentItemId={currentItemId}
                item_color_dropdown={item_color_dropdown}
                item_size_dropdown={item_size_dropdown}
                handleChangeValue={this.handleChangeValue}
                handleRemove={this.handleRemove}
                inPopup={true}
              />
            )}
            {item_color_dropdown && item_color_dropdown.length > 1 && this.state.allow_add_color ? <a onClick={e => {e.preventDefault(); this.addColor();}}>+ Add another color</a> : null}
          </div>
        :
          <input type="text" ref="quantity" value={this.state.color_breakdowns[0].quantity_color} placeholder="Quantity" onChange={e => this.handleChangeValue(this.refs.quantity.value, null, null, null, null)} onFocus={this.onFocus} />
        }
      </div>
    );
  }

  renderSelectSku() {
    const { full_item, item_color_dropdown, item_size_dropdown, currentItemId } = this.props;
    const onChange = quantities => {
      this.setState({ quantities, allow_save: Object.values(quantities).reduce((t, q) => t + q, 0) > 0 });
    };
    return (
        <CartSkuSelect skus={full_item.item_skus} onChange={onChange} quantities={this.state.quantities} inPopup={true} />
    );
  }

  renderLoading() {
    return (
      <div className='row popup-content column'>
        <div className='small-12 columns'>
          <div className='small-12 text-center'><br /><img src='/images/gears.gif' /><br /></div>
        </div>
      </div>
    );
  }

  render() {
    const { full_item, onClosePopup } = this.props;
    const { loading } = this.state;

    return (
      <div className="reveal gallery-popup" style={{display: 'block', height: 'inherit', overflowY: 'auto'}} aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3 id="modalTitle">Add Quantity</h3>
          </div>
        </div>
        {loading ?
          this.renderLoading()
        :
          <Fragment>
            {full_item.item_skus.length ? this.renderSelectSku() : this.renderSelectSizeColor()}
            <button className="button" style={{position: 'absolute', right: '9rem', top: '0.5rem'}} aria-label="Close modal" type="button" onClick={e => {e.preventDefault(); onClosePopup();}}>Cancel</button>
            <button className="button alt" style={{position: 'absolute', right: '1rem', top: '0.5rem'}} aria-label="Save info" type="button" onClick={e => {e.preventDefault(); this.handleSave(); }} disabled={!this.state.allow_save}>Add to Order</button>
          </Fragment>
        }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const full_item = (state.entities && state.entities.items && ownProps.currentItemType === 'OPTION') ? getFullOptionItem(state, { item_id: ownProps.currentItemId }) : state.items && ownProps.currentItemType === 'OPTION' ? Object.values(state.items).filter(i => i.item_id == ownProps.currentItemId)[0] : null;
  let filtered_color_list = full_item.item_colors.reduce((o, c) => { o[c.color_id] = c; return o;}, {});
  let filtered_size_list = full_item.item_sizes.reduce((o, s) => { o[s.size_id] = s; return o;}, {});
  _.forEach(filtered_size_list, function(value, key) {
    value.quantity = 0;
    delete value.item_size_id;
    delete value.item_id;
    delete value.display_order;
  });
  let item_color_dropdown = full_item.item_colors.map(c => ({ key: c.color_id, value: toTitleCase(c.color_name) }));
  let item_size_dropdown = full_item.item_sizes.map(s => ({ key: s.size_id, value: s.size_name }));
  let quote = state.temp[ownProps.currentItemId] ? state.temp[ownProps.currentItemId] : null;

  return {
    full_item,
    filtered_color_list,
    filtered_size_list,
    item_color_dropdown,
    item_size_dropdown,
    quote
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClosePopup: () => {
      dispatch(closePopup());
    },
    onCreateAddTemp: (key, value) => {
      dispatch(createAddTemp(key, value));
    },
    onCreateCartSummaryPopup: () => {
      dispatch(createCartSummaryPopup());
    }
  };
};

const ConnectedAddToQuotePopup = connect(mapStateToProps, mapDispatchToProps)(AddToQuotePopup);
export default ConnectedAddToQuotePopup;

